import type { UseFetcherOptions } from '@/repositories/useMutation';
import useMutation from '@/repositories/useMutation';
import type { BaseResponseApi } from '@/types/general/responseApi';

import submitLoan from '.';
import type { BLSubmitLoanBodyRequest, ACDSubmitLoanBodyRequest, ResponseSubmitLoan } from './types';

const useSubmitLoan = (options?: UseFetcherOptions<BaseResponseApi<ResponseSubmitLoan>>) => {
  const resp = useMutation<BaseResponseApi<ResponseSubmitLoan>, BLSubmitLoanBodyRequest | ACDSubmitLoanBodyRequest>(submitLoan, options);
  return resp;
};

export default useSubmitLoan; 