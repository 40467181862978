import type { ChangeEvent } from 'react';
import { useCallback, useMemo, useState } from 'react';

import { ButtonPrimary } from '@pinvest/sirius-button';
import { Info16Icon } from '@pinvest/sirius-icons';
import { TextField } from '@pinvest/sirius-input';
import Popup from '@pinvest/sirius-popup/dist/popup';
import clsx from 'clsx';

import Text from '@/components/shared/Text';
import formatCurrency from '@/utils/formatCurrency';
import addThousandSeparator from '@/utils/number/addThousandSeparator';
import {
  numberRegex,
  decimalNumberWithCommaRegex
} from '@/utils/regex/inputRegex';

import {
  ACDServiceFee,
  loanInstallmentTerms,
  agentCommissionDenominator,
  ACDCommissionPercentage,
  oneHundredThousand,
  defaultFieldState,
  unitQuantityDefaultValue,
  loanInstallmentTermsDefaultValue,
  commissionPercentageDefaultValue
} from './config';
import styles from './styles.module.css';
import type { ACDB2BCalculatorProps, FieldState } from './types';

// eslint-disable-next-line max-lines-per-function
const ACDB2BCalculator = ({ handleOnApply, isMobileSticky = false }: ACDB2BCalculatorProps) => {
  const [propertyPrice, setPropertyPrice] = useState<FieldState>(defaultFieldState);
  const [commissionPercentage, setCommissionPercentage] = useState<FieldState>({ ...defaultFieldState, value: commissionPercentageDefaultValue });

  const [tenure, setTenure] = useState(loanInstallmentTermsDefaultValue);

  const [isShowIMark, setIsShowIMark] = useState(false);

  const handlePropertyPriceChanged = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const parsedValueNonSeparator: string = event.target.value.replace(
        /\./g,
        ''
      );

      const parsedThousandSeparator: string = parseInt(parsedValueNonSeparator)
        ? addThousandSeparator(parseInt(parsedValueNonSeparator))
        : '';

      if (!numberRegex.test(parsedValueNonSeparator)) {
        setPropertyPrice({
          ...propertyPrice,
          error: Error('Harga properti harus berupa angka'),
          text: parsedThousandSeparator
        });
        return;
      }

      setPropertyPrice({
        ...propertyPrice,
        text: parsedThousandSeparator,
        error: undefined,
        value: parseInt(parsedValueNonSeparator)
      });
    }, [propertyPrice]);

  const handleCommissionPercentageChanged = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const value: string = event.target.value;

      if (!decimalNumberWithCommaRegex.test(value)) {
        setCommissionPercentage({
          ...commissionPercentage,
          error: Error('Persentase komisi tidak valid')
        });
        return;
      }

      const parsedValue: string = value.replace(',', '.');

      setCommissionPercentage({
        ...commissionPercentage,
        error: undefined,
        value: parseFloat(parsedValue)
      });
    }, [commissionPercentage]);

  const handleTenureChanged = useCallback(
    (event: ChangeEvent<HTMLSelectElement>) => {
      const value: string = event.target.value;

      setTenure(parseInt(value));
    }, []);

  const calculatedAgentCommission = useMemo(() => {
    if (!propertyPrice && !commissionPercentage) {
      return 0;
    }

    return Math.round((propertyPrice.value * (commissionPercentage.value / 100) * unitQuantityDefaultValue) / agentCommissionDenominator);
  }, [commissionPercentage, propertyPrice]);

  const calculatedCommissionFromPinhome = useMemo(() => {
    if (!calculatedAgentCommission) {
      return 0;
    }

    return (
      Math.floor((calculatedAgentCommission * ACDCommissionPercentage) / oneHundredThousand) * oneHundredThousand
    );
  }, [calculatedAgentCommission]);

  const calculatedAdminFee = useMemo(() => {
    if (!calculatedCommissionFromPinhome) {
      return 0;
    }

    return (2 / 100) * calculatedCommissionFromPinhome;
  }, [calculatedCommissionFromPinhome]);

  const calculatedServiceFee = useMemo(() => {
    if (!calculatedCommissionFromPinhome || !tenure) {
      return 0;
    }

    return calculatedCommissionFromPinhome * (ACDServiceFee.b2b / 100) * tenure;
  }, [calculatedCommissionFromPinhome, tenure]);

  const calculatedTotalServiceFee = useMemo(() => {
    if (!calculatedServiceFee || !calculatedCommissionFromPinhome) {
      return 0;
    }

    return calculatedCommissionFromPinhome;
  }, [calculatedCommissionFromPinhome, calculatedServiceFee]);

  const calculatedDisbursedCommission = useMemo(() => {
    if (!calculatedAdminFee || !calculatedCommissionFromPinhome) {
      return 0;
    }
    return calculatedCommissionFromPinhome - calculatedAdminFee - calculatedServiceFee;
  }, [calculatedAdminFee, calculatedCommissionFromPinhome, calculatedServiceFee]);


  const isFormValid = useMemo(() => {
    if (
      !propertyPrice.value ||
      !commissionPercentage.value ||
      !tenure
    ) {
      return false;
    }

    if (propertyPrice.error || commissionPercentage.error) {
      return false;
    }

    return true;
  }, [commissionPercentage, propertyPrice, tenure]);


  const estimationRepaymentText = useMemo(() => {
    const formattedCalculatedTotalServiceFee = formatCurrency(parseInt(calculatedTotalServiceFee.toFixed(0)));

    return `${formattedCalculatedTotalServiceFee}`;
  }, [calculatedTotalServiceFee]);

  const toggleIMark = useCallback(() => {
    setIsShowIMark(!isShowIMark);
  }, [isShowIMark]);

  return (
    <>
      <div className={styles['acd-b2b__simulation-calculator']}>
        <div className={styles['acd-b2b__simulation-form']}>
          <div className={styles['acd-b2b__simulation-form-input_full']}>
            <label className={styles['form__item-label']}>
              <Text typography="label2" component='p' margin={'0 5px 0 0'}>Total Harga Jual Properti</Text>
              <Info16Icon onClick={toggleIMark} data-testid='btn-simulation-imark-button' />
            </label>
            <TextField
              prefix="Rp"
              placeholder="1.000.000.000"
              description={
                propertyPrice.error?.message || 'Masukkan total harga jual (setelah PPN) dari satu atau beberapa properti yang ingin diajukan'
              }
              value={propertyPrice.text}
              onChange={handlePropertyPriceChanged}
              hasError={!!propertyPrice.error}
              data-testid='txt-acdB2b-propertyPriceInput'
            />
          </div>
          <div className={styles['acd-b2b__simulation-form-input']}>
            <Text typography="label2">Tenor Pengembalian</Text>
            <select
              className={styles['acd-b2b__simulation-form-select']}
              required
              onChange={handleTenureChanged}
              data-testid='ddl-acdB2b-tenureInput'
              defaultValue={loanInstallmentTermsDefaultValue}
            >
              <option value="" disabled>
                Pilih jangka waktu
              </option>
              {loanInstallmentTerms.map((term, idx) => (
                <option key={`${term}-${idx}`} value={term}>
                  {`${term} bulan`}
                </option>
              ))}
            </select>
          </div>
          <div className={styles['acd-b2b__simulation-form-input']}>
            <Text typography="label2">Rata-rata Komisi Agen</Text>
            <TextField
              suffix="%"
              placeholder="0"
              description={
                commissionPercentage.error?.message ||
                'Rata-rata komisi untuk agen'
              }
              onChange={handleCommissionPercentageChanged}
              hasError={!!commissionPercentage.error}
              data-testid='txt-acdB2b-commissionPercentageInput'
              defaultValue={commissionPercentageDefaultValue}
            />
          </div>
        </div>
      </div>
      {isFormValid &&
        <div>
          <div className={clsx(styles['acd-b2b__simulation-estimation'], styles['acd-b2b__simulation-estimation_green'])}>
            <div
              className={styles['acd-b2b__simulation-estimation-text']}
            >
              <Text typography="label1">
                Total Komisi Instan yang Dapat Dicairkan
              </Text>
            </div>
            <div
              className={styles['acd-b2b__simulation-estimation-text']}
            >
              <Text typography="heading2" color="positive">
                {formatCurrency(parseInt(calculatedDisbursedCommission.toFixed(0)))}
              </Text>
            </div>
            <div
              className={styles['acd-b2b__simulation-estimation-text']}
            >
              <Text typography="body3">
                Sudah dipotong biaya admin & layanan sebesar {' '}
                <Text typography="label3"> {formatCurrency(parseInt((calculatedServiceFee + calculatedAdminFee).toFixed(0)))}</Text>
              </Text>
            </div>
          </div>
          <div className={clsx(styles['acd-b2b__simulation-estimation'], styles['acd-b2b__simulation-estimation_subtle'])}>
            <div
              className={styles['acd-b2b__simulation-estimation-text']}
            >
              <Text typography="label1" margin="0 0 4px">
                Total Tagihan yang Harus Dibayar
              </Text>
            </div>
            <div
              className={styles['acd-b2b__simulation-estimation-text']}
            >
              <Text typography="heading2" color="attentive">
                {estimationRepaymentText}
              </Text>
            </div>
            <div
              className={styles['acd-b2b__simulation-estimation-text']}
            >
              <Text typography="body3" margin="0 0 4px">
                Dibayar pada saat jatuh tempo
              </Text>
            </div>
          </div>
        </div>
      }
      <div className={clsx(styles['acd-b2b__simulation-estimation-cta'], isMobileSticky && styles['acd-b2b__simulation-estimation-cta_sticky'])}>
        <ButtonPrimary
          fullWidth
          onClick={handleOnApply}
          target="_blank"
          disabled={!isFormValid}
          testId='btn-acdb2b-landing-page-simulation-apply'
        >
          Ajukan Sekarang
        </ButtonPrimary>
      </div>
      <Popup isOpen={isShowIMark} testId='simulation-popup-imark'>
        <div className={styles['acd-b2b__simulation-estimation__imark']}>
          <div className={styles['acd-b2b__simulation-estimation__imark-title']}>
            <Text typography='heading3'>
              Contoh Total Harga Jual Properti
            </Text>
          </div>
          <div className={styles['acd-b2b__simulation-estimation__imark-content']}>
            <Text typography='body1'>
              Pengajuan Komisi Instan untuk 2 properti dengan harga jual Rp600 juta dan Rp400 juta (setelah PPN).
            </Text>
          </div>
          <div className={styles['acd-b2b__simulation-estimation__imark-content']}>
            <Text typography='body1'>
              <Text typography='label1'>Total Harga Jual Properti = Rp 1 miliar</Text> {' '}(Rp600 juta + Rp400 juta)
            </Text>
          </div>
          <div className={styles['acd-b2b__simulation-estimation__imark-cta']}>
            <ButtonPrimary testId='btn-simulationPopup-close-button' onClick={toggleIMark}>Tutup</ButtonPrimary>
          </div>
        </div>
      </Popup>
    </>
  );
};

export default ACDB2BCalculator;
