import type { FieldState } from './types';

export enum repaymentMethodList {
  oneTime = 'repayment_method.one_time',
  installment = 'repayment_method.installment'
}

export const repaymentOptions = [
  { label: 'Satu Kali Pembayaran', value: repaymentMethodList.oneTime },
  { label: 'Cicilan', value: repaymentMethodList.installment }
];

export enum ACDServiceFee {
  b2b = 1.59,
  individual = 2.5
}
export const loanInstallmentTerms = [2, 3, 4, 5, 6];

export const agentCommissionDenominator = 1.1;

export const ACDCommissionPercentage = 30 / 100;

export const oneHundredThousand = 100_000;

export const defaultFieldState: FieldState = {
  error: undefined,
  text: '',
  value: 0
};

export const unitQuantityDefaultValue = 1;
export const repaymentMethodDefaultValue = repaymentMethodList.oneTime;
export const loanInstallmentTermsDefaultValue = 6;
export const commissionPercentageDefaultValue = 2;