import fetcher from '@/repositories/fetcher';
import { getHeadersWithBasicAuth } from '@/repositories/utils';
import { getAccessToken } from '@/services/cookies/oauth';
import type { BaseResponseApi } from '@/types/general/responseApi';

import type { BLSubmitLoanBodyRequest, ACDSubmitLoanBodyRequest, ResponseSubmitLoan } from './types';

export const submitLoan = async (body: BLSubmitLoanBodyRequest | ACDSubmitLoanBodyRequest) => {
  const data = await fetcher<BaseResponseApi<ResponseSubmitLoan>>({
    method: 'POST',
    url: '/api/loan/submit-loan',
    body,
    headers: {
      ...getHeadersWithBasicAuth(),
      Accept: 'application/json',
      'x-auth': getAccessToken()?.value ?? ''
    }
  });

  return data;
};

export default submitLoan;