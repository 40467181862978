import { useCallback, useReducer } from 'react';

import { fetchReducer, reducerInitialState } from './utils';

type UseFetcherResponse<T, S> = {
  isLoading: boolean;
  error: any;
  data: T | null;
  fetch: (body: S) => Promise<T | undefined>;
};

export type UseFetcherOptions<T> = {
  lazyFetch?: boolean;
  onSuccess?: (data: T) => void;
  onError?: (error: any) => void;
};

type UseMutationParams<T, S = any> = (body: S) => Promise<T>;

// POST | PUT | PATCH with body request is required OR GET with required slug 
// used to create/update/delete/get
const useMutation = <T, S>(fetcher: UseMutationParams<T, S>, options?: UseFetcherOptions<T>): UseFetcherResponse<T, S> => {
  const [state, dispatch] = useReducer(fetchReducer, reducerInitialState);

  const handleSuccess = useCallback((data: T) => {
    if (typeof options?.onSuccess === 'function') {
      options.onSuccess(data);
    }
  }, [options]);

  const handleError = useCallback((err: any) => {
    if (typeof options?.onError === 'function') {
      options.onError(err);
    }
  }, [options]);

  const handleFetch = useCallback(async (body: S) => {
    try {
      dispatch({ type: 'loading', payload: true });
      const resp = await fetcher(body);
      dispatch({ type: 'data', payload: resp });
      handleSuccess(resp);
      return resp;
    } catch (err) {
      const parsedError = typeof err === 'string' ? JSON.parse(err) : err;
      dispatch({ type: 'error', payload: parsedError });
      handleError(parsedError);
    }
  }, [fetcher, handleSuccess, handleError]);

  return {
    data: state.data,
    isLoading: state.isLoading,
    error: state.error,
    fetch: handleFetch
  };
};

export default useMutation;

