import type { ChangeEvent } from 'react';
import { useCallback, useState } from 'react';

import { IconButton, ButtonPrimary, ButtonOutline } from '@pinvest/sirius-button';
import Checkbox from '@pinvest/sirius-checkbox/dist/checkbox';
import { Close16Icon, Success16Icon } from '@pinvest/sirius-icons';
import Popup from '@pinvest/sirius-popup/dist/popup';
import snackbar from '@pinvest/sirius-snackbar/dist/snackbar';
import Image from 'next/image';

import Spinner from '@/components/shared/Spinner';
import Text from '@/components/shared/Text';
import { LOAN_PRODUCT_TYPE } from '@/constants/loan';
import useSubmitLoan from '@/repositories/loan/submit-loan/useSubmitLoan';

import { SubmissionPopUpIllustration } from './config';
import styles from './styles.module.css';
import type { SubmissionPopUpProps } from './types';

const SubmissionPopUp = ({ isShowSubmissionPopUp = false, closeModal, userEmail, succeedCallback }: SubmissionPopUpProps) => {

  const [agreeField, setAgreeField] = useState(false);

  const handleSubmitLoanSuccess = useCallback(() => {
    closeModal();
    snackbar.show({
      variant: 'positive',
      content: 'Berhasil mengirim template pengajuan.',
      icon: <Success16Icon color='white' />
    });

    if (succeedCallback) {
      succeedCallback();
    }

  }, [closeModal, succeedCallback]);

  const handleSubmitLoanFailed = useCallback(() => {
    snackbar.show({
      variant: 'negative',
      content: 'Terjadi kesalahan pada server, mohon coba kembali nanti'
    });
  }, []);

  const {
    isLoading,
    fetch: submitLoan
  } = useSubmitLoan({
    onSuccess: handleSubmitLoanSuccess,
    onError: handleSubmitLoanFailed
  });

  const handleOnSentClicked = useCallback(async () => {
    await submitLoan({
      productTypeLocaleKey: LOAN_PRODUCT_TYPE.ACD_B2B
    });
  }, [submitLoan]);

  const handleAgreementCheck = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const value: boolean = event.target.checked;

    setAgreeField(value);
  }, []);

  return (<Popup testId='popup-submission' isOpen={isShowSubmissionPopUp} >
    <div className={styles['submission__popup']}>
      <div className={styles['submission__popup-header']}>
        <div className={styles['submission__popup-header-image']}>
          <Image
            alt='dashboard-popup-success-illustration' src={SubmissionPopUpIllustration}
            layout='responsive' objectFit='contain'
            width={128} height={65} />
        </div>
        <div className={styles['submission__popup-header-button']}>
          <IconButton onClick={closeModal} disabled={isLoading} testId='btn-submission-modalClose' icon={<Close16Icon />} />
        </div>
      </div>
      <div className={styles['submission__popup-body']}>
        <Text typography='heading2' component='p' margin={'0 0 12px'}>Template Pengajuan Akan Dikirim ke Email Anda</Text>
        <Text typography='body2' component='p' margin={'0 0 12px'}>
          Silakan isi template pengajuan Komisi Instan yang akan kami kirimkan ke email Anda di <b>{userEmail}</b>.
        </Text>
        <div className={styles['submission__popup-checkbox__wrapper']}>
          <div className={styles['submission__popup-checkbox']}>
            <Checkbox name={''} checked={agreeField} testId='chk-createLoanRequest-modal' onChange={handleAgreementCheck} />
          </div>
          <Text typography='body2' color='subtle'>
            Dengan ini saya menyetujui data profil saya digunakan untuk pengecekan Pefindo
          </Text>
        </div>
      </div>
      <div className={styles['submission__popup-ctas']}>
        <div className={styles['submission__popup-cta']}>
          <ButtonOutline testId='btn-submission-modalCancel' onClick={closeModal} fullWidth disabled={isLoading}>Batal</ButtonOutline>
        </div>
        <div className={styles['submission__popup-cta']}>
          <ButtonPrimary testId='btn-submission-modalApply' onClick={handleOnSentClicked} fullWidth disabled={isLoading || !agreeField}>
            {isLoading ? <Spinner color='white' size='standard' data-testid='spinner-submission-modalApply' /> : 'Kirim Template Pengajuan'}
          </ButtonPrimary>
        </div>
      </div>
    </div>
  </Popup>);
};

export default SubmissionPopUp;