import { thousandSeparatorPositionRegex } from '@/utils/regex/commonRegex';

const langToSeparator = {
  id: '.',
  en: ','
};


const ONE_THOUSAND = 1000;

export default function addThousandSeparator(value: number, lang : 'id' | 'en' = 'id'): string {
  if (!value && typeof value !== 'number') return '';
  
  if (value < ONE_THOUSAND && value > -ONE_THOUSAND) return value.toString();
  return value.toString().replace(
    thousandSeparatorPositionRegex,
    langToSeparator[lang] ?? langToSeparator.id
  );
}
